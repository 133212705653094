import { render, staticRenderFns } from "./CarrierCard.vue?vue&type=template&id=0b9502ca&scoped=true&"
import script from "./CarrierCard.vue?vue&type=script&lang=js&"
export * from "./CarrierCard.vue?vue&type=script&lang=js&"
import style0 from "./CarrierCard.vue?vue&type=style&index=0&id=0b9502ca&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b9502ca",
  null
  
)

export default component.exports